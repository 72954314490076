import React, { useState, useEffect } from "react";
import { Header } from "../common/header";
import { Footer } from "../common/footer";
import { Helmet } from "react-helmet";

import "../assets/scss/_main.scss"
const Layout = (props) => {
    const windowGlobal = typeof window !== 'undefined' && window;
    const [size, setSize] = useState(1920);

    useEffect(() => {
        setSize(windowGlobal.innerWidth);
        const handleResize = () => {            
            setSize(window.innerWidth);            
        };
        window.addEventListener("resize", handleResize);
    }, [])

    const { children, infoPage } = props
    return (
        <>
            <Helmet>
                <title>{infoPage.page} | Gifthy</title>
            </Helmet>
            <div className="main">
                <Header infoPage={infoPage} smallScreen={size <= 840} />
                <div>{children}</div>
                <Footer />
            </div>
        </>
    )
}

export default Layout
