import React from "react"
import Intent from "./link"
import { Helmet } from "react-helmet"

import logo from "../../../assets/img/header/menu/ic-logo.png";

const MenuItems = (props) => {
    const infoPage = props.infoPage;
    const page = infoPage.page;
    const subPage = infoPage.sub ? infoPage.sub : false;
    const system = process.env.GATSBY_SYSTEM_WEB;
    
    const handlePageName = () => {
        let allName = page.charAt(0).toUpperCase() + page.slice(1);
        if(subPage) {
            allName = allName + ' - ' + subPage.charAt(0).toUpperCase() + subPage.slice(1);
        }

        allName = allName + ` | Gifthy`;
        return allName;
    }
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{handlePageName()}</title>
                <link rel="canonical" href="https://gifthy.com.br" />
                {/* <meta http-equiv="refresh" content="0; URL='https://app.gifthy.com.br/login'"/> */}
            </Helmet>
            <header className={!props.isMobile ? 'menu' : 'menu mobile'}>
                <div className={props.isMobile ? 'display-none' : null}>
                    <Intent to="/">
                        <img src={logo} alt="Crafty Logo" className="logo" />
                    </Intent>
                </div>
                <div className="d-f-a-c">
                    {
                        props.isMobile ? 
                        <div className="menu__box">
                            <Intent to="/">
                                <span className={page === "/" ? "menu--active" : "button"}>
                                    Home
                                </span>
                            </Intent>
                        </div>
                        : null
                    }
                    <div className="menu__box">
                        
                        <a href="#por-que">
                            <span className={page === "sobre" ? "menu--active" : "button"}>
                                Por que
                            </span>
                        </a>
                    </div>
                    <div className="menu__box" >
                        <a href="#faq">
                            <span className={page === "tecnologia" ? "menu--active" : "button"}>
                                FAQs
                            </span>
                        </a>
                    </div>
                    <div className="menu__box">
                        <a href="#contato">
                            <span className={page === "contato" ? "menu--active" : "button"}>
                                Contato
                            </span>
                        </a>
                    </div>
                    <div className="auth__menus">
                        <a href={`/temp`} target="_blank">
                            <span className={page === "contato" ? "menu--active" : "button"}>
                                ENTRAR
                            </span>
                        </a>
                        <span className="or">ou</span>
                        <a href={`/temp`} target="_blank">
                            <span className={page === "contato" ? "menu--active" : "button"}>
                                CRIAR CONTA
                            </span>
                        </a>
                    </div>
                </div>
            </header>
        </>   
    )
};

export default MenuItems;