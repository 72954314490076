import React from "react"
import logo from "../../assets/img/header/menu/ic-logo-only-2.png";

const Footer = () => {
    return (
        <footer className="footer upper">
            <div className="wrapper">
                <div className="footer__main__info">
                    <img className="footer__img" src={logo} alt="logo" />
                    <a href="mailto:contato@gifthy.com.br" target="_blank">contato@gifthy.com.br</a>
                    <span>Porto Alegre - RS - Brazil</span>
                    <a href="tel:+555133785208" target="_blank">(51) 3378-5208</a>
                    <span>GIFTHY NETWORK SERVICOS DE</span>
                    <span>INTERMEDIACAO DE NEGOCIOS DE</span>
                    <span>INFORMATICA LTDA</span>
                    <span>36.959.425/0001-70</span>
                </div>
                <section className="main">
                    <div className="links">
                        <article>
                            <h3>Aplicativos</h3>
                            <div>
                                <span>Android & Chrome OS</span>
                                <span>Apple</span>
                                <span>Web</span>
                            </div>
                        </article>
                        <article>
                            <h3>Institucional</h3>
                            <div>
                                <span>Política de privacidade</span>
                                <span>Termos de uso</span>
                                <span>FAQs</span>
                            </div>
                        </article>
                        <article>
                            <h3>Comunidade</h3>
                            <div>
                                <span>API</span>
                                <span>Desenvoldedores</span>
                                <span>Meios de Pagamento</span>
                            </div>
                        </article>
                        <article>
                            <h3>Aplicativos</h3>
                            <div>
                                <span>Sobre</span>
                                <span>Imprensa</span>
                                <span>Carreiras</span>
                            </div>
                        </article>
                    </div>
                    <div className="infos">
                        <span>
                            Copyright © {new Date().getFullYear()} <b>Gifthy</b>. All Right Reserved - Por: <a href="https://adac.dev/" target="_blank" rel="noreferrer">adac.dev</a>
                        </span>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </section>
            </div>
        </footer>

    )
}

export default Footer;
