import React from "react"
import Top from './Top'
import Bottom from './Bottom'

const Footer = () => {
    return (
      <>
        <Top />
        {/* <Bottom /> */}
      </>
    )
  }
export default Footer
